<script>
	import SuperInput from '$lib/Components/Forms/SuperInput/SuperInput.svelte';

	import countries from './countryList.json';

	export let form;
	export let style = '';
	export let error = '';
	export let name = 'country';
	export let label = 'Country';
	export let autocomplete = 'country-name';
	export let disabled = null;
</script>

<SuperInput
	{form}
	class={[$$props.class ?? ''].join(' ')}
	options={countries}
	valueField="name"
	labelField="name"
	type="select"
	{name}
	{label}
	{style}
	{error}
	{autocomplete}
	{disabled}
/>
